<template>
  <!-- 选择文件组件 -->
  <div class="choose-file">
    <div class="choose-file-placeholder" @click="handlePlaceholderClick">
      <slot />
    </div>

    <input
      ref="input"
      class="choose-file-input"
      type="file"
      :multiple="multiple"
      @change="handleFileChange"
    />
  </div>
</template>

<script>
  /**
   * props: accept，允许的 mime 数组
   *        extensions，允许的文件扩展名数组，对 mime 的兼容和补充(某些文件的 mime 并不如预期返回，如: .docx 返回空)
   *        filename，允许的文件名正则表达式
   *        size，允许的文件大小，单位 Byte，默认 Infinity
   * slots: default
   * methods: chooseFile()，弹出选择文件对话框
   * events: on-mime-invalid，所选文件的 mime 不包含在传入的 accept 中
   *         on-size-exceeded，所选文件的大小超出传入的 size 值
   *         on-filename-mismatch, 所选文件的文件名与传入的文件名正则表达式不匹配
   *         on-file-choose，所选文件满足以上限制条件，返回选择的文件列表
   */
  export default {
    name: 'choose-file',
    props: {
      // 允许的文件 mime 数组
      accept: {
        type: Array,
        default: () => [],
      },
      // 允许的文件扩展名数组，对 accept 选项的补充
      extensions: {
        type: Array,
        default: () => [],
      },
      // 允许的文件大小，单位: Byte
      size: {
        type: Number,
        default: Infinity,
      },
      // 允许的文件名正则表达式
      filename: {
        type: RegExp,
        default: () => /^.+$/,
      },
      // 是否允许多选
      multiple: {
        type: Boolean,
        default: undefined,
      },
    },
    methods: {
      // 供父组件调用，用于触发选择文件的操作
      chooseFile() {
        this.$refs.input.click();
      },
      handlePlaceholderClick() {
        this.chooseFile();
      },
      handleFileChange(e) {
        const inputEl = e.target;
        const files = Array.from(inputEl.files);
        const accept = this.accept;
        const extensions = this.extensions;
        const mimeInvalidFiles = [];
        const sizeExceededFiles = [];
        const filenameMismatchFiles = [];

        files.forEach(file => {
          const { type, name, size } = file;
          const fileExt = name.slice(name.lastIndexOf('.') + 1);

          // 校验文件 mime 类型
          accept.length &&
            !accept.includes(type) &&
            mimeInvalidFiles.push(file);

          // 校验文件扩展名
          extensions.length &&
            !extensions.includes(fileExt) &&
            mimeInvalidFiles.push(file);

          // 校验文件大小
          size > this.size && sizeExceededFiles.push(file);

          // 校验文件文件名
          !name.match(this.filename) && filenameMismatchFiles.push(file);
        });

        mimeInvalidFiles.length &&
          this.$emit('on-mime-invalid', mimeInvalidFiles);
        sizeExceededFiles.length &&
          this.$emit('on-size-exceeded', sizeExceededFiles);
        filenameMismatchFiles.length &&
          this.$emit('on-filename-mismatch', filenameMismatchFiles);

        !mimeInvalidFiles.length &&
          !sizeExceededFiles.length &&
          !filenameMismatchFiles.length &&
          this.$emit('on-file-choose', files);

        // 防止再次选择同名文件时不触发事件
        inputEl.value = '';
      },
    },
  };
</script>

<style scoped lang="scss">
  .choose-file {
    display: inline-flex;

    .choose-file-placeholder {
      flex-grow: 1;
    }

    .choose-file-input {
      display: none;
      width: 0;
      height: 0;
    }
  }
</style>
