<!-- promotion-edit -->
<template>
  <el-dialog
    class="promotion-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="promotion-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="95px"
      @submit.native.prevent
    >
      <el-form-item label="游戏名称:" prop="name">
        <el-input v-model.trim="model.name" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="游戏简介:" prop="introduction">
        <el-input
          v-model.trim="model.introduction"
          placeholder="请输入"
          clearable
        />
      </el-form-item>

      <el-form-item label="安卓落地页:" prop="androidLandUrl">
        <el-input
          v-model.trim="model.androidLandUrl"
          placeholder="请输入"
          clearable
        />
      </el-form-item>

      <el-form-item label="IOS落地页:" prop="iosLandUrl">
        <el-input
          v-model.trim="model.iosLandUrl"
          placeholder="请输入"
          clearable
        />
      </el-form-item>

      <el-form-item class="from-poster" label="游戏海报:" prop="poster">
        <div class="form-poster">
          <choose-file
            :extensions="posterFileExtensions"
            :size="posterFileSize"
            @on-mime-invalid="handlePosterFileTypeInvalid"
            @on-size-exceeded="handlePosterFileSizeExceeded"
            @on-file-choose="handlePosterFileChoose"
          >
            <div class="poster-img">
              <div v-if="!model.poster" class="img-choose" />

              <template v-else>
                <img class="img-thumbnail" :src="model.poster" />

                <img class="img-preview" :src="model.poster" />
              </template>
            </div>
          </choose-file>

          <div class="poster-error">{{ posterFileErrorMsg }}</div>
        </div>
      </el-form-item>

      <el-form-item label="推广状态:" prop="status">
        <el-radio-group v-model="model.status">
          <el-radio
            v-for="item in promotionStatusList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>

    <poster-cropper
      v-model="showCropperDialog"
      :image="posterFile"
      :width="320"
      :ratio="2 / 1"
      @on-close="handleCropperClose"
      @on-ok="handleCropperOkClick"
    />

    <template v-slot:footer>
      <div class="promotion-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消</el-button
        >

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
        >
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import ChooseFile from '../../../../components/choose-file';
  import promotionStatus from '../../../../enum/promotion-status';
  import {
    getPromotion,
    addPromotion,
    updatePromotion,
  } from '../../../../api/operation/promotion';

  export default {
    name: 'promotion-edit',
    components: {
      ChooseFile,
      PosterCropper: () =>
        import(
          /* webpackChunkName: 'promotion-edit-cropper' */ '../../../../components/image-cropper'
        ),
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 当前编辑的推广对象
        promotion: null,
        model: null,
        rules: {
          name: [{ required: true, message: '请填写', trigger: 'change' }],
          introduction: [
            { required: true, message: '请填写', trigger: 'change' },
          ],
          androidLandUrl: [
            { required: true, message: '请填写', trigger: 'change' },
          ],
          iosLandUrl: [
            { required: true, message: '请填写', trigger: 'change' },
          ],
          poster: [{ required: true, message: '请填写', trigger: 'change' }],
          status: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        // 海报图片文件
        posterFile: '',
        posterFileExtensions: ['png', 'jpg', 'jpeg'],
        posterFileSize: 2 * 1024 * 1024,
        // 海报图片错误文案
        posterFileErrorMsg: '',
        // 是否显示裁切图片对话框
        showCropperDialog: false,
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState('operation/promotion', ['promotionId']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.promotionId;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}推广`;
      },
      // 推广状态列表
      promotionStatusList() {
        const { map } = promotionStatus;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      ...mapActions(['actUserInfo']),

      // 初始化模型
      initModel(data = {}) {
        const {
          name = '',
          introduction = '',
          androidLandUrl = '',
          iosLandUrl = '',
          poster = '',
          status = promotionStatus.enum.offline,
        } = data || {};

        this.model = {
          name,
          introduction,
          androidLandUrl,
          iosLandUrl,
          poster,
          status,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.promotionId,
        };
        const method = this.isEdit ? updatePromotion : addPromotion;
        const res = await method(params);

        return !!res;
      },
      // 获取推广对象详情
      async getPromotion() {
        const res = await getPromotion(this.promotionId);

        if (!res) return false;

        this.promotion = res.data;

        return true;
      },
      // 海报文件 mime 无效
      handlePosterFileTypeInvalid() {
        this.posterFileErrorMsg = `请选择 ${this.posterFileExtensions.join(
          '、'
        )} 类型的图片`;
      },
      // 海报文件大小超出
      handlePosterFileSizeExceeded() {
        this.posterFileErrorMsg = `请选择大小不超过 ${
          this.posterFileSize / 1024 / 1024
        }MB 的图片`;
      },
      // 海报文件选取完成
      handlePosterFileChoose(files) {
        this.posterFileErrorMsg = '';

        const posterFile = files[0];

        if (!posterFile) return;

        this.posterFile = posterFile;
        this.showCropperDialog = true;
      },
      // 图片裁切对话框取消按钮单击
      handleCropperClose() {
        this.posterFile = '';
        this.showCropperDialog = false;
      },
      // 图片裁切对话框确定按钮单击
      handleCropperOkClick({ base64 }) {
        this.model.poster = base64;
        this.showCropperDialog = false;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getPromotion();

        this.loading = false;

        if (!success) return;

        this.initModel(this.promotion);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.promotion = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .promotion-edit {
    .promotion-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }

      .form-poster {
        display: flex;
        position: relative;

        .poster-img {
          position: relative;
          width: 120px;
          height: 60px;
          cursor: pointer;

          .img-choose {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: dashed 1px #409eff;
            border-radius: 3px;

            &::before {
              content: '+';
              margin-top: -5px;
              font-size: 26px;
              font-weight: 400;
              color: #409eff;
            }
          }

          .img-thumbnail {
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }

          .img-preview {
            display: none;
            position: absolute;
            top: 0;
            left: 105%;
            z-index: 1;
            width: 300px;
            height: 150px;
            border-radius: 5px;
          }

          &:hover .img-preview {
            display: block;
          }
        }

        .poster-error {
          position: absolute;
          bottom: -24px;
          left: 0;
          font-size: 12px;
          color: #f56c6c;
        }
      }
    }
  }
</style>
